const checkUpload = (input) => {
  if(input.files[0]) {
    input.parentElement.firstElementChild.classList.add("text-christmas");
  }
}

const uploadAttachments = () => {
  // we select the photo input
  const inputs = document.querySelectorAll("[type='file']");
  if (inputs) {
    // we add a listener to know when a new picture is uploaded
    inputs.forEach(input => {
      input.addEventListener("change", () => {
        checkUpload(input);
      });
    });
  }
}

export { uploadAttachments };
