const bioCard = () => {
  const openBioButtons = document.querySelectorAll(".bio-btn");
  const closeBioButtons = document.querySelectorAll(".close");
  const bioCards = document.querySelectorAll(".bio-card");

  openBioButtons.forEach((openBioButton) => {
    openBioButton.addEventListener("click", (e) => {
      bioCards.forEach((bioCard) => {
        bioCard.classList.add("hide");
      });
      const modal = e.currentTarget.parentElement.nextElementSibling;
      console.log(modal);
      modal.classList.remove("hide");
      document.body.classList.add("overflow-hidden");
    });
  });

  closeBioButtons.forEach((closeBioButton) => {
    closeBioButton.addEventListener("click", (e) => {
      const modal = e.currentTarget.parentElement.parentElement;
      modal.classList.add("hide");
      bioCards.forEach((bioCard) => {
        bioCard.classList.remove("hide");
      });
      document.body.classList.remove("overflow-hidden");
    });
  })
}

export { bioCard };
