const opacityFader = () => {
  if (!document.querySelector(".opacity-element")) return null;

  function debounce(func, wait = 40, immediate = true) {
    var timeout;
    return function () {
      var context = this, args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  const checkSlide = () => {
    opacityElements.forEach(opacityElement => {
      const slideInAt = (window.scrollY + window.innerHeight) - (opacityElement.style.height / 2);
      const imageBottom = opacityElement.offsetTop + opacityElement.style.height;
      const isShown = slideInAt > opacityElement.offsetTop;
      const isNotScrolledPast = window.scrollY < imageBottom;
      if (isShown && isNotScrolledPast) {
        opacityElement.classList.add("active");
        if (opacityElement.nodeName === 'VIDEO') {
          opacityElement.playbackRate = 1.5;
          opacityElement.play()
        };
      }
      // else {
      //   if (opacityElement.parentElement.classList.contains("process-card")) opacityElement.classList.remove("active");
      // }
    })
  }

  const opacityElements = document.querySelectorAll(".opacity-element");
  window.addEventListener("scroll", debounce(checkSlide));
}

export { opacityFader }
