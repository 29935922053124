const setPosition = (members) => {
  members.forEach((member) => {
    const index = Array.prototype.indexOf.call(members, member);
    if (index === 0) {
      member.classList.add("active");
    }
    if (index > 0 && index < (members.length - 1)) {
      member.classList.add("next");
      // member.style.opacity = "0";
    }
    if (index === members.length - 1) {
      member.classList.add("previous");
      // member.style.opacity = "0";
    }
  });
  return members[0];
};

const setHeight = (currentSlide) => {
  const membersDiv = document.querySelector(".members");
  membersDiv.style.height = `${currentSlide.offsetHeight}px`;
};

const previousSlide = (membersDiv, members) => {
  const currentSlide = membersDiv.querySelector(".active");
  const previousSlide = currentSlide.previousElementSibling || membersDiv.lastElementChild;
  const nextSlide = currentSlide.nextElementSibling || membersDiv.firstElementChild;
  // FOR TEMP FIX
  // previousSlide.style.opacity = '1';
  // nextSlide.style.opacity = '0';
  members.forEach(member => member.classList.remove("active", "previous", "next"));

  // currentSlide.classList.remove("active");
  currentSlide.classList.add("next");

  // previousSlide.classList.remove("previous");
  previousSlide.classList.add("active");

  // nextSlide.classList.remove("next");
  nextSlide.classList.add("previous");
  setHeight(previousSlide);
}

const nextSlide = (membersDiv, members) => {
  const currentSlide = membersDiv.querySelector(".active");
  const previousSlide = currentSlide.previousElementSibling || membersDiv.lastElementChild;
  const nextSlide = currentSlide.nextElementSibling || membersDiv.firstElementChild;
  // TEMP FIX
  // previousSlide.style.opacity = '0';
  // nextSlide.style.opacity = '1';
  members.forEach(member => member.classList.remove("active", "previous", "next"));

  // currentSlide.classList.remove("active");
  currentSlide.classList.add("previous");

  // previousSlide.classList.remove("previous");
  previousSlide.classList.add("next");

  // nextSlide.classList.remove("next");
  nextSlide.classList.add("active");
  setHeight(nextSlide);
}

const slider = () => {

  if (document.querySelector("#previous") && window.innerWidth > 767) {
    return null;
  };

  if (!window.location.href.includes("team")) {
    return null;
  };

  const sliderButtons = document.querySelectorAll(".slider-btn");
  const members = document.querySelectorAll(".member");
  members.forEach(member => member.classList.add("opacity-0"));
  const currentSlide = setPosition(members);
  const membersDiv = document.querySelector(".members");
  sliderButtons.forEach((sliderButton) => {
    sliderButton.addEventListener("click", () => {
      if (sliderButton.id === "next") { nextSlide(membersDiv, members)};
      if (sliderButton.id === "previous") { previousSlide(membersDiv, members) };
    });
  });
  setHeight(currentSlide);
};

export { slider };
