const hamburger = () => {
  if (!document.querySelector(".menu-btn")) return null;

  const button = document.querySelector(".menu-btn");
  const modal = document.querySelector(".modal");
  const navbar = document.querySelector("nav");
  button.addEventListener("click", () => {
    button.classList.toggle("open");
    modal.classList.toggle("-translate-y-full");
    navbar.classList.toggle("shadow-md");
  })
};

export { hamburger };
