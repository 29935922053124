const filterFader = () => {
  if (!document.querySelector(".filter-element")) return null;

  function debounce(func, wait = 30, immediate = true) {
    let timeout;
    return function () {
      const context = this, args = arguments;
      const later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  const checkSlide = () => {
    filterElements.forEach(filterElement => {
      const slideInAt = (window.scrollY + window.innerHeight) - filterElement.style.height;
      const imageBottom = filterElement.offsetTop + filterElement.style.height;
      const isShown = slideInAt > filterElement.offsetTop;
      const isNotScrolledPast = window.scrollY < imageBottom;
      if (isShown && isNotScrolledPast) {
        filterElement.classList.add("active");
      }
      // else {
      //   if (filterElement.parentElement.classList.contains("process-card")) filterElement.classList.remove("active");
      // }
    })
  }

  const filterElements = document.querySelectorAll(".filter-element");
  window.addEventListener("scroll", debounce(checkSlide));
}

export { filterFader }
